import { Button, Divider, Spin, Table } from "antd";
import { GET_LAPORAN_FINANCE } from "API/API";
import { GET_PARAM_GROUP_KOMPONEN, GET_PARAM_KOMPONEN } from "API/Params";
import _ from "lodash";
import moment from "moment";
import { setRumus } from "pages/UserDireksi/function";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { currFormat } from "util/functions";
import { notif } from "util/notification";

export default function KinerjaKeuangan({
  kodeKantor,
  date,
  kodeCabang,
  changeTab,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [laporanData, setLaporanData] = useState([]);
  const [laporanDataExtend, setLaporanDataExtend] = useState([]);
  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colLastMonth, setColLastMonth] = useState("");
  const [colLastYear, setColLastYear] = useState("");
  const [colRbb, setColRbb] = useState("");
  const [colRealisasi, setColRealisasi] = useState("");
  // const [desc, setDesc] = useState("")
  useEffect(() => {
    getData();
    setPeriode();
    changeTab("1");
  }, [kodeKantor, date]);

  const setPeriode = () => {
    let lastMonth = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let colLastMonth = moment(moment(lastMonth, "YYYY-MM-DD")).format(
      "DD MMMM YYYY"
    );
    let lastYear = moment(date)
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");
    let colLastYear = moment(moment(lastYear, "YYYY-MM-DD")).format(
      "MMMM YYYY"
    );
    setColLastYear(colLastYear);
    setColLastMonth(colLastMonth);
    setColRbb(moment(moment(date, "YYYY-MM-DD")).format("MMMM YYYY"));
    setColRealisasi(moment(moment(date, "YYYY-MM-DD")).format("DD MMMM YYYY"));
  };
  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await GET_PARAM_KOMPONEN("?page=1&limit=1000");
      const { data: group } = await GET_PARAM_GROUP_KOMPONEN(
        "?page=1&limit=1000"
      );
      group.docs.map((e) => {
        e.nourut =
          e.name === "Main"
            ? 1
            : e.name === "Saldo dan Pos Utama"
            ? 2
            : e.name === "Biaya"
            ? 3
            : e.name === "Rasio"
            ? 4
            : 0;
      });
      // let totalDPK = {code: "DPK-Total"}
      group.docs.sort((a, b) => a.nourut - b.nourut);
      console.log("group.docs", group.docs);
      setGroup(group.docs);
      // console.log("komp",data.docs)
      getValueData(data.docs);

      // console.log("main",mainKomisraris, saldoPos, combineData)
    } catch (e) {
      notif("error", "Gagal!", "Gagal dalam mengambil data!");
    } finally {
    }
  };

  const handleDataExtend = (comp, datas) => {
    console.log("handleDataExtend", { comp, datas });

    // setLoading(true);
    let param = comp;
    let data = datas;
    var merged = _.merge(_.keyBy(param, "name"), _.keyBy(data, "name"));
    var values = _.values(merged);
    const filterUser = "PINCA";
    const result = values.filter((item) => {
      return item.visibility.indexOf(filterUser) >= 0;
    });
    result.sort((a, b) => a.nourut - b.nourut);
    result.map((e) => {
      e.capaianRp = Number(e.realisasiValue) - Number(e.rbbValue);
      e.capaianPersent = (Number(e.realisasiValue) / Number(e.rbbValue)) * 100;
      e.rpGrowth = Number(e.realisasiValue) - Number(e.lastYearValue);
      e.percentGrowth = (Number(e.rpGrowth) / Number(e.lastYearValue)) * 100;
      e.mutasi = Number(e.realisasiValue) - Number(e.lastMonthValue);
    });
    let findPendapatan = result.filter((e) => e.group.name === "Pendapatan");
    let findBiaya = result.filter((e) => e.group.name === "Biaya");

    // console.log("findPendapatan", findPendapatan)
    let totalPendapatanLM = 0;
    let totalPendapatanLY = 0;
    let totalPendapatan = 0;
    findPendapatan.forEach(
      ({ realisasiValue, lastMonthValue, lastYearValue }) => {
        totalPendapatan += Number(realisasiValue);
        totalPendapatanLM += Number(lastMonthValue);
        totalPendapatanLY += Number(lastYearValue);
      }
    );
    // console.log("", )
    let totalBiayaLM = 0;
    let totalBiayaLY = 0;
    let totalBiaya = 0;
    findBiaya.forEach(({ realisasiValue, lastMonthValue, lastYearValue }) => {
      totalBiaya += Number(realisasiValue);
      totalBiayaLM += Number(lastMonthValue);
      totalBiayaLY += Number(lastYearValue);
    });

    let totalLabaLY = totalPendapatanLY - totalBiayaLY;
    let totalLabaLM = totalPendapatanLM - totalBiayaLM;
    let totalLaba = totalPendapatan - totalBiaya;
    // console.log("totalBiayaDIR", totalBiayaLY)
    // console.log("totalPendapatanDIR", totalPendapatanLY)
    let findLaba = result.find((e) => e.code === "700");
    findLaba.realisasiValue = totalLaba;
    findLaba.lastMonthValue = totalLabaLM;
    findLaba.lastYearValue = totalLabaLY;
    setLaporanDataExtend(result);
    // setLoading(false);

    // const rasioData = result.filter((i) => i.group.name === "Rasio");
    // console.log("handleDataExtens rasioData", rasioData);

    // return rasioData;
  };

  const handleData = (comp, datas) => {
    console.log("handleData", { comp, datas });

    setLoading(true);
    let param = comp;
    let data = datas;
    var merged = _.merge(_.keyBy(param, "name"), _.keyBy(data, "name"));
    var values = _.values(merged);
    let result = values;
    // result.sort((a, b) => a.code - b.code);
    // console.log("result", result)
    let findPendapatan = result.filter((e) => e.group.name === "Pendapatan");
    let findBiaya = result.filter((e) => e.group.name === "Biaya");

    // console.log("PendapatanFind",findPendapatan)

    // console.log("findPendapatan", findPendapatan)
    let totalPendapatanLM = 0;
    let totalPendapatanLY = 0;
    let totalPendapatan = 0;
    findPendapatan.forEach(
      ({ realisasiValue, lastMonthValue, lastYearValue }) => {
        totalPendapatan += Number(realisasiValue);
        totalPendapatanLM += Number(lastMonthValue);
        totalPendapatanLY += Number(lastYearValue);
      }
    );
    // console.log("findBiaya", findBiaya)
    let totalBiayaLM = 0;
    let totalBiayaLY = 0;
    let totalBiaya = 0;
    findBiaya.forEach(({ realisasiValue, lastMonthValue, lastYearValue }) => {
      totalBiaya += Number(realisasiValue);
      totalBiayaLM += Number(lastMonthValue);
      totalBiayaLY += Number(lastYearValue);
    });

    let totalLabaLY = totalPendapatanLY - totalBiayaLY;
    let totalLabaLM = totalPendapatanLM - totalBiayaLM;
    let totalLaba = totalPendapatan - totalBiaya;

    let findLaba = result.find((e) => e.code === "700");
    findLaba.realisasiValue = totalLaba;
    findLaba.lastMonthValue = totalLabaLM;
    findLaba.lastYearValue = totalLabaLY;

    result.map((e) => {
      e.mutasiAwal = Number(e.lastMonthValue) - Number(e.twoLastMonthVal);
      e.mutasiAkhir = Number(e.realisasiValue) - Number(e.lastMonthValue);
      e.capaianRp = Number(e.realisasiValue) - Number(e.lastYearValue);
      e.capaianPersent = (Number(e.capaianRp) / Number(e.lastYearValue)) * 100;
      e.rpGrowth = Number(e.realisasiValue) - Number(e.lastYearValue);
      e.percentGrowth = (Number(e.rpGrowth) / Number(e.lastYearValue)) * 100;
    });
    const mainKomisraris = result.filter(
      (e) =>
        e.code === "401" ||
        e.code === "409" ||
        e.code === "501" ||
        e.code === "502" ||
        e.code === "504" ||
        e.code === "509" ||
        e.code === "601" ||
        e.code === "602" ||
        e.code === "700"
    );
    mainKomisraris.sort((a, b) => a.code - b.code);

    mainKomisraris.forEach((e) => {
      e.group = { name: "Main", calculated: false };
    });
    const saldoPos = result.filter(
      (e) =>
        e.code === "130" ||
        e.code === "331" ||
        e.code === "332" ||
        e.code === "101" ||
        e.code === "102" ||
        e.code === "402"
    );
    saldoPos.forEach((e) => {
      e.group = { name: "Saldo dan Pos Utama", calculated: false };
    });

    let combineData = [...mainKomisraris, ...saldoPos];
    console.log("Combining", combineData);
    // setGroup(combineData)
    setLaporanData(combineData);
    setLoading(false);
  };

  const getValueData = async (component) => {
    setLoading(true);
    let lastMonth = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let lastMonth2 = moment(date)
      .subtract(2, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let colLastMonth = moment(moment(lastMonth, "YYYY-MM-DD")).format(
      "DD MMMM YYYY"
    );
    let lastYear = moment(date)
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");
    let colLastYear = moment(moment(lastYear, "YYYY-MM-DD")).format(
      "MMMM YYYY"
    );
    var currMonthName = moment().format("MMM");
    // console.log("cur", currMonthName)
    setColLastYear(colLastYear);
    setColLastMonth(colLastMonth);
    let paramKodeCabang =
      kodeCabang !== "00" ? `kodeCabang=${kodeCabang}&` : "";
    try {
      const { data: realisasi } = await GET_LAPORAN_FINANCE(
        `?${paramKodeCabang}date=${date}&hide=nosbbs,neraca`
      );
      const { data: lastMonth_ } = await GET_LAPORAN_FINANCE(
        `?${paramKodeCabang}date=${lastMonth}&hide=nosbbs,neraca`
      );
      const { data: lastMonthTwo_ } = await GET_LAPORAN_FINANCE(
        `?${paramKodeCabang}date=${lastMonth2}&hide=nosbbs,neraca`
      );
      const { data: lastYear_ } = await GET_LAPORAN_FINANCE(
        `?${paramKodeCabang}date=${lastYear}&hide=nosbbs,neraca`
      );

      let data = [];
      let r = setRumus(realisasi.data, date);
      let lm = setRumus(lastMonth_.data, lastMonth);
      let ly = setRumus(lastYear_.data, lastYear);
      let lm2 = setRumus(lastMonthTwo_.data, lastMonth2);
      component.map((e) => {
        let realisasiVal = r.filter((item) => item.code === e.code);
        let lastMonthVal = lm.filter((item) => item.code === e.code);
        let lastYearVal = ly.filter((item) => item.code === e.code);
        let twoLastMonthVal = lm2.filter((item) => item.name === e.name);
        let dataname = {
          name: e.name,
          code: e.code,
          realisasiValue: 0,
          lastMonthValue: 0,
          lastYearValue: 0,
          rbbValue: 0,
          twoLastMonthVal: 0,
        };

        realisasiVal.forEach((val) => {
          dataname.realisasiValue = val.sum?.raw;
        });

        lastMonthVal.forEach((val) => {
          dataname.lastMonthValue = val.sum?.raw;
        });
        twoLastMonthVal.forEach((val) => {
          dataname.twoLastMonthVal = val.sum?.raw;
        });
        lastYearVal.forEach((val) => {
          dataname.lastYearValue = val.sum?.raw;
        });
        data.push(dataname);
      });
      handleDataExtend(component, data);
      handleData(component, data);
    } catch (e) {
      notif("error", "Gagal!", "Gagal dalam mengambil data!");
    } finally {
      setLoading(false);
    }
  };

  const handleCapaianRp = (data) => {
    const total = Number(data.realisasiValue) - Number(data.lastYearValue);
    return currFormat(total);
  };
  const handleCapaianPersent = (data) => {
    const total = data.capaianPersent;
    return (
      <div className={total < 0 ? "gx-text-danger" : "gx-text-success"}>
        {total ? `${total.toFixed(2)} %` : ""}{" "}
      </div>
    );
  };

  const columns = [
    {
      title: "Uraian",
      // dataIndex: "name",
      width: 200,
      key: "id",
      render: (record) => record.name,
      // fixed: "left"
    },
    {
      title: colLastYear,
      render: (record) => currFormat(record.lastYearValue),
      width: 120,
      key: "id",
      align: "right",
    },

    {
      title: "Mutasi",
      children: [
        {
          title: colLastMonth,
          render: (record) => currFormat(record.mutasiAwal),
          width: 120,
          key: "id",
          align: "right",
        },
        {
          title: colRealisasi,
          render: (record) => currFormat(record.mutasiAkhir),
          width: 110,
          align: "right",
        },
      ],
    },
    {
      title: "Akumulasi",
      children: [
        {
          title: colRealisasi,
          render: (record) => currFormat(record.realisasiValue),
          width: 110,
          align: "right",
        },
      ],
    },

    {
      title: `Pertumbuhan dari ${colLastYear}`,
      children: [
        {
          title: "(Rp.)",
          render: (record) => handleCapaianRp(record),
          width: 100,
          align: "right",
        },
        {
          title: "%",
          render: (record) => handleCapaianPersent(record),
          width: 80,
          align: "right",
        },
      ],
    },
  ];

  const columnsExtend = [
    {
      title: <div className="gx-text-center">Uraian</div>,
      dataIndex: "name",
      width: !isMobile ? 240 : 120,
      key: "id",
      fixed: "left",
    },
    {
      title: <div className="gx-text-center">{colLastYear}</div>,
      render: (record) =>
        record.group.name !== "Rasio"
          ? currFormat(record.lastYearValue)
          : record.lastYearValue + "%",
      width: 145,
      key: "id",
      align: "right",
      footer: (data) => {
        return (
          <div>
            Summary:{" "}
            {data.reduce((sum, record) => sum + record.lastYearValue, 0)}
          </div>
        );
      },
    },
    {
      title: <div className="gx-text-center">{colLastMonth}</div>,
      render: (record) =>
        record.group.name !== "Rasio"
          ? currFormat(record.lastMonthValue)
          : record.lastMonthValue + "%",
      width: 145,
      key: "id",
      align: "right",
    },
    {
      title: "RBB",
      children: [
        {
          title: <div className="gx-text-center">{colRbb}</div>,
          render: (record) =>
            record.group.name !== "Rasio"
              ? currFormat(record.rbbValue)
              : record?.rbbValue + "%",
          width: 145,
          align: "right",
        },
      ],
    },
    {
      title: "REALISASI",
      children: [
        {
          title: <div className="gx-text-center">{colRealisasi}</div>,
          render: (record) =>
            record.group.name !== "Rasio"
              ? currFormat(record.realisasiValue)
              : record.realisasiValue + "%",
          width: 145,
          align: "right",
        },
      ],
    },

    {
      title: "Capaian",
      children: [
        {
          title: <div className="gx-text-center">(Rp.)</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? handleCapaianRp(record) : "",
          width: 155,
          align: "right",
        },
        {
          title: <div className="gx-text-center">%</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? handleCapaianPersent(record) : "",
          width: 100,
          align: "right",
        },
      ],
    },
    {
      title: "Mutasi",
      children: [
        {
          title: <div className="gx-text-center">{colRealisasi}</div>,
          render: (record) =>
            record.name.includes("Laba Th")
              ? currFormat(record.realisasiValue - record.lastMonthValue)
              : record.group.name !== "Rasio"
              ? currFormat(record.mutasi)
              : "",
          width: 145,
          align: "right",
        },
      ],
    },
    {
      title: `Pertumbuhan dari ${colLastYear}`,
      children: [
        {
          title: <div className="gx-text-center">Rp.</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? (
              record.realisasiValue - record.lastYearValue >= 0 ? (
                currFormat(record.realisasiValue - record.lastYearValue)
              ) : (
                <span
                  className="gx-text-danger gx-text-right"
                  style={{ textAlign: "right !important" }}
                >
                  ({currFormat(record.realisasiValue - record.lastYearValue)})
                </span>
              )
            ) : (
              ""
            ),
          width: 155,
          align: "right",
        },
        {
          title: <div className="gx-text-center">%</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? (
              <p
                className={
                  (_.round(record.realisasiValue - record.lastYearValue) /
                    record.lastYearValue) *
                    10 <=
                  0
                    ? "gx-text-danger"
                    : "gx-text-success"
                }
              >
                {`${
                  _.ceil(
                    (_.round(record.realisasiValue - record.lastYearValue) /
                      record.lastYearValue) *
                      100,
                    2
                  ) || 0
                } %`}
              </p>
            ) : (
              ""
            ),
          width: 100,
          align: "right",
        },
      ],
    },
  ];

  const totalData = (pageData, flag) => {
    // console.log("pageda",pageData, flag)
    let rbbVal = 0;
    let realisasiVal = 0;
    let lastMonthVal = 0;
    let lastYearVal = 0;
    pageData.forEach(
      ({ rbbValue, realisasiValue, lastMonthValue, lastYearValue }) => {
        rbbVal += Number(rbbValue);
        realisasiVal += Number(realisasiValue);
        lastMonthVal += Number(lastMonthValue);
        lastYearVal += Number(lastYearValue);
      }
    );
    return (
      <>
        <Table.Summary.Row className="gx-bg-light ">
          <Table.Summary.Cell className="gx-text-dark">
            Jumlah {flag}
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(lastYearVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(lastMonthVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(rbbVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(realisasiVal)}
            </div>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const groupby = React.useMemo(
    () =>
      group.filter((e) => _.includes(["Main", "Saldo dan Pos Utama"], e.name)),
    [group]
  );
  // console.log("grupby",groupby);
  return (
    <div className="gx-mt-0" style={{ marginTop: -20 }}>
      {groupby.map((e, i) => {
        return (
          <div key={i}>
            <div className="kinerja-keuangan">
              <div className={e.name !== "Main" ? "pendapatan-table" : ""}>
                {e.name !== "Main" ? (
                  <p className="gx-bg-light gx-text-dark gx-p-2 gx-mt-2">
                    {e.name}
                  </p>
                ) : (
                  ""
                )}
                <Table
                  dataSource={laporanData.filter(
                    (i) => i.group.name === e.name
                  )}
                  columns={columns}
                  rowKey="id"
                  size="middle"
                  pagination={false}
                  scroll={{ x: 1080 }}
                  bordered
                  className="gx-card"
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: loading,
                    tip: "Mohon menunggu, Sedang Mengambil Komponen Data...",
                  }}
                  tip={"Sedang Mengambil Data.."}
                  summary={
                    e.calculated
                      ? (pageData) => totalData(pageData, e.name)
                      : null
                  }
                />
              </div>
            </div>
          </div>
        );
      })}

      {group
        .filter((e) => _.includes(["Rasio"], e.name))
        .map((e, i) => (
          <div key={i}>
            <div className="kinerja-keuangan">
              <div className={""}>
                {e.name !== "Main" ? (
                  <p className="gx-bg-light gx-text-dark gx-p-3 gx-mt-3">
                    {e.name}
                  </p>
                ) : (
                  ""
                )}
                <Table
                  dataSource={laporanDataExtend.filter(
                    (i) => i.group.name === e.name
                  )}
                  columns={columnsExtend}
                  rowKey="id"
                  size="middle"
                  pagination={false}
                  scroll={{ x: 1360 }}
                  bordered
                  className="gx-card"
                  loading={{
                    indicator: (
                      <div>
                        <Spin />
                      </div>
                    ),
                    spinning: loading,
                    tip: "Mohon menunggu, Sedang Mengambil Komponen Data...",
                  }}
                  tip={"Sedang Mengambil Data.."}
                  summary={
                    e.calculated
                      ? (pageData) => totalData(pageData, e.name)
                      : null
                  }
                />
              </div>
            </div>
          </div>
        ))}

      <div>
        {kodeKantor !== "00" && (
          <div>
            <Divider>SARAN KOMISARIS </Divider>
            {/* <Input.TextArea rows="5" onChange={(e) => setDesc(e.target.value)} /> */}
            <Button
              className="gx-btn-block gx-mt-2"
              type="primary"
              onClick={() => changeTab("2")}
            >
              Tambahkan Saran
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
